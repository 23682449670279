import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const StepProcess = () => {
  return (
    <>
      <div className="container">
        <Row className="justify-content-center">
          <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div className="card">
              <div className="card-body text-center">
                <h4 className="m-3">Discovery</h4>
                <StaticImage
                  placeholder="blurred"
                  className="logo-static-img mt-2 mb-3"
                  imgClassName="img-fluid w-100 h-100"
                  src="../../images/new-images/discovery.png"
                  alt="search"
                  layout="constrained"
                  width={128}
                  loading="lazy"
                />
              </div>
            </div>
            <p className="h5 mt-5 text-black font-weight-bold text-center">
              2 Weeks
            </p>
            <p className>
              We run a detailed inventory of your entire tech stack, interview
              your team to evaluate abilities, needs, goals, and pain points to
              compare against our benchmarks.
            </p>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div className="card">
              <div className="card-body text-center">
                <h4 className="m-3">Audit</h4>
                <StaticImage
                  placeholder="blurred"
                  className="logo-static-img mt-2 mb-3"
                  imgClassName="img-fluid w-100 h-100"
                  src="../../images/new-images/search.png"
                  alt="discovery"
                  layout="constrained"
                  width={128}
                  loading="lazy"
                />
              </div>
            </div>
            <p className="h5 mt-5 text-black font-weight-bold text-center">
              2 Weeks
            </p>
            <p className>
              We run five reports: Software Utilization, Staffing Optimization,
              Cost-Benefit Analysis, Integration Assessment, and Security
              Review.
            </p>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div className="card">
              <div className="card-body text-center">
                <h4 className="m-3">Delivery</h4>
                <StaticImage
                  placeholder="blurred"
                  className="logo-static-img mt-2 mb-3"
                  imgClassName="img-fluid w-100 h-100"
                  src="../../images/new-images/delivered.png"
                  alt="delivery"
                  layout="constrained"
                  width={128}
                  loading="lazy"
                />
              </div>
            </div>
            <p className="h5 mt-5 text-black text-center font-weight-bold">
              1 Week
            </p>
            <p className>
              We deliver a detailed presentation of recommendations:
              consolidating tools, renegotiating contracts, upgrading solutions,
              and identifying workflow improvements.
            </p>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div className="card">
              <div className="card-body text-center">
                <h4 className="m-3">Implementation</h4>
                <StaticImage
                  placeholder="blurred"
                  className="logo-static-img mt-2 mb-3"
                  imgClassName="img-fluid w-100 h-100"
                  src="../../images/new-images/operation.png"
                  alt="operation"
                  layout="constrained"
                  width={128}
                  loading="lazy"
                />
              </div>
            </div>
            <p className="h5 mt-5 text-black font-weight-bold text-center">
              2 Weeks
            </p>
            <p className>
              If needed, we can guide your internal team or work as an extension
              of them to implement improvements using our agile approach.
            </p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default StepProcess
